<template>
  <div class="contactUs">
    <Header></Header>
    <div class="contactUs-main">
      <div
        class="contactUs-title"
        style="margin-top:32px"
      >联系我们</div>
      <div
        class="contactUs-tips"
        style="margin-top:18px"
      >官方客服电话：400-777-8802 (9:00-21:00)</div>
      <div
        class="contactUs-txt"
        style="margin-top:13px"
      >此号码为易借速贷唯一官方服务电话，其他均为诈骗电话，请您提高警惕，谨防上当。</div>
      <div
        class="contactUs-tips"
        style="margin-top:28px"
      >官方投诉电话：0571-87608950 (工作日 9:00-18:00)</div>
      <div
        class="contactUs-txt"
        style="margin-top:13px"
      >若您收到任何由催收公司人员发出的含有侮辱、恐吓内容，或含有其他不文明、不合法内容的短信、电话等，您可通过投诉电话进行投诉。我们在收到您的投诉后将认真核实情况，并予以处理。</div>
      <div
        class="contactUs-second-title"
        style="margin-top:28px;margin-bottom:60px"
      >
        商务合作电话：17706521236<br><br>
        媒体联系电话：15669948658
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/mobile/header'
import Footer from '@/components/mobile/footer'

export default {
  components: { Header, Footer },
}
</script>

<style lang="less">
.contactUs {
  display: flex;
  flex-direction: column;

  &-main {
    margin-top: 44px;
    display: flex;
    flex-direction: column;
    margin-left: 38px;
    margin-right: 38px;
  }

  &-wechat {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &-title {
    font-size: 15px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #1e1f20;
    line-height: 21px;
  }

  &-second-title {
    font-size: 13px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #1e1f20;
    line-height: 18px;
  }

  &-tips {
    font-size: 13px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #ee6c28;
    line-height: 18px;
  }

  &-txt {
    font-size: 13px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #4f4f4f;
    line-height: 22px;
  }

  &-img {
    width: 112px;
    height: 112px;
    margin-top: 11px;
  }
}
</style>